<template>
	<div class="vld-parent">
	<loading :active="loading" color="#D9F7C5" loader="bars"/>
	</div>
	<div class="p-grid crud-demo">
		<div class="p-col-12">
			<div class="card" id="xyz">
				<h4 class="p-m-2">Créditos & Abonos</h4>
				<Toast/>
		<div class="p-field p-m-1">
			<label for="id_moneda" class="p-m-1">Moneda</label>
			<Dropdown v-model="idMoneda" :options="lkpMonedas" optionLabel="moneda" optionValue="id_moneda" @change="generar" placeholder="Seleccionar"/>					
		</div>				
        <DataTable :value="ctahabientes" dataKey="id_cta_habiente_info" v-model:expandedRows="expandedRows" selectionMode="single" responsiveLayout="scroll"
		:loading="loadingCtaHabientes">      
             <Column :expander="true" headerStyle="width: 3rem" />               		
            <Column field="cta_habiente" :header="ctaHabienteTitulo"/>                                        
            <Column field="disponible" header="Crédito Disponible" headerStyle="text-align:right" bodyStyle="text-align:right" :sortable="true"> 
				<template #body="slotProps">
					<div style="color: green;">
					{{slotProps.data.id_moneda}} {{formatCurrency(slotProps.data.disponible)}}
					</div>
				</template>
			</Column>				
			<Column field="saldo" header="Saldo Por Pagar" headerClass="p-d-flex p-jc-end" bodyStyle="text-align:right" :sortable="true"> 
				<template #body="slotProps">
				<div style="color: red;">
					{{slotProps.data.id_moneda}} {{formatCurrency(slotProps.data.saldo)}}
				</div>
				</template>
			</Column>						    
            <template #expansion="slotProps">
                <div class="orders-subtable">
					<h6>Documentos con Saldo Pendiente de Aplicar</h6>
                    <DataTable :value="slotProps.data.docs" dataKey="id_movimiento" responsiveLayout="scroll" class="p-datatable-sm"  selectionMode="single">
						<Column field="fecha" header="Fecha"></Column>
                        <Column field="no_docto" header="#Documento">
                          <template #body="slotProps">
                                {{slotProps.data.serie}}-{{ formatDocument(slotProps.data.no_docto)}}
                            </template>
						</Column>
                        <Column field="observaciones" header="Observaciones"></Column>
						<!-- <Column field="total" header="Total" bodyStyle="text-align:right">
                          <template #body="slotProps">
                                {{slotProps.data.id_moneda}} {{formatCurrency(slotProps.data.total)}}
                            </template>
                        </Column> -->
						<Column field="saldo" header="Crédito Disponible" headerClass="p-d-flex p-jc-end" bodyStyle="text-align:right">					
                            <template #body="slotProps">
                                 {{slotProps.data.id_moneda}} {{formatCurrency(slotProps.data.saldo)}}
                            </template>
                        </Column>
						<Column>
							<template #body="slotProps">							
								<Button v-if="slotProps.data.saldo > 0" text="Aplicar" icon="pi pi-cog" class="p-button-rounded p-button-success p-mr-2" @click="aplicar(slotProps.data)" />
							</template>
						</Column>
                    </DataTable>
                </div>
            </template>									    
			<!-- <ColumnGroup type="footer">
				<Row>
					<Column footer="CtaHabientes con Crédito Disponible:" :colspan="2" footerStyle="text-align:right" />
					<Column :footer="ctahabientes.length"/>
					<Column footer="Total Disponible:" :colspan="2" footerStyle="text-align:right" />
					<Column>
						<template>
							{{ this.total_disponible }}						
						</template>
					</Column>									
				</Row>
			</ColumnGroup>					 -->
        </DataTable>						
			</div>
		<Dialog v-model:visible="documentosConSaldoDlg" :style="{width: '1150px', height: '600px'}"  :showHeader="true" :modal="true" >
			<template #header>
				<div class="p-fluid">											
					<div class="p-fluid p-m-1">
						<h4 >
						Documentos con Saldo
						</h4>				
					</div>				
				</div>
			</template>			
			<div :style="{height: '425px'}">		
			<div class="p-fluid p-m-1" >					
				<h5 >{{ docSeleccionado.cta_habiente }}</h5>
			</div>
			<Fieldset legend="Información Documento de Pago" class="p-mb-2">
				<div class="p-fluid p-formgrid p-grid">				
					<div class="p-field p-m-1">
						<label for="tipo_documento" class="p-text-bold">Tipo</label>																																					
						<InputText id="tipo_documento" type="text" v-model="docSeleccionado.tipo" disabled/>	
					</div>					
					<div class="p-field p-m-1">
						<label for="documento_saldo" class="p-text-bold"># Documento</label>																																					
						<InputText id="documento_saldo" type="text" v-model="docSeleccionado.documento" disabled/>	
					</div>	
					<div class="p-field p-m-1">
						<label for="fecha_doc" class="p-text-bold">Fecha</label>
						<Calendar id="fecha_doc" v-model="docSeleccionado.fecha" disabled/>
					</div>	
					<div class="p-field p-m-1">
						<label for="saldo_disponible" class="p-text-bold">Saldo Disponible </label>
						<InputText id="saldo_disponible" type="text" v-model="saldoDisponible" disabled/>											
					</div>							
					<div class="p-field p-m-1">
						<label for="observaciones" class="p-text-bold">Observaciones</label>
						<InputText size="135" id="observaciones" type="text" v-model="docSeleccionado.observaciones" disabled/>	
					</div>							
				</div>	
			</Fieldset>			
			<DataTable :value="cargosPendientes" :rowClass="rowClass" class="p-datatable-sm" :scrollable="true" scrollHeight="flex" selectionMode="single" dataKey="id_cuota">
			<Column field="fecha" header="Fecha" style="min-width:200px"></Column>
			<Column field="nombre" header="Tipo"></Column>	
			<Column field="no_docto" header="No.Docto">
				<template #body="slotProps">							
					{{slotProps.data.serie}}-{{ slotProps.data.no_docto }}
				</template>				
			</Column>	
			<Column field="fel_serie" header="#FEL" :style="felHabilitado?'':'display: none'">
				<template #body="slotProps">							
					{{slotProps.data.fel_serie}}-{{slotProps.data.fel_numero}}
				</template>								
			</Column>
			<Column field="referencia" header="Referencia"/>						
			<Column field="fecha_pago" header="Fecha Pago"></Column>		
			<Column field="saldo" header="Saldo" dataType="numeric" style="min-width: 8rem">
				<template #body="slotProps">
					{{formatCurrency(slotProps.data.saldo)}}
				</template>
			</Column>	
			<Column field="pago" header="Pago">
				<template #body="slotProps">
					<InputNumber v-bind:id="slotProps.data.id_cuota" v-model="slotProps.data['pago']" mode="decimal" :minFractionDigits="2" :min="0.00" v-bind:max="slotProps.data.saldo" />	
				</template>
			</Column>																								
			<ColumnGroup type="footer">
				<Row>
					<Column footer="Documentos:" :colspan="1" footerStyle="text-align:right" />
					<Column :footer="cargosPendientes.length"/>
					<Column footer="Saldo Total:" :colspan="3" footerStyle="text-align:right"/>
					<Column :footer="formatCurrency(saldoTotal)" />
					<Column footer="Total a Pagar:" :colspan="3" footerStyle="text-align:right" />
					<Column :footer="formatCurrency(totalPagos)" footerStyle="text-align:left"/>			
				</Row>
			</ColumnGroup>							
			</DataTable>
			</div>
			<template #footer>
				<div class="p-field p-col">
					<Button v-if="totalPagos>0" label="Aplicar" icon="pi pi-check" class="p-button-text" :disabled="totalPagos>docSeleccionado.saldo" @click="aplicarDocumento"/>
					<Button label="Cerrar" icon="pi pi-times" class="p-button-text" @click="documentosConSaldoDlg=false"/>
				</div>
			</template>
		</Dialog>
		</div>
	</div>
</template>

<script>
import Loading from 'vue-loading-overlay';
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';
import CxcService from '../service/cxc.service';
import { CURRENT_APP_MODE } from '../service/constants';


export default {
	setup() {
		const rowClass = (data) => {
            return data.anulado === 'S' ? 'row-anulada': null;
        };

		return { rowClass }
	},	
    components: {
        Loading
    },
	data() {
		return {
			idMoneda: this.$store.state.auth.currentAppCtx.monedaBase.id_moneda,
            appMode: CURRENT_APP_MODE,
			ctaHabienteTitulo: CURRENT_APP_MODE==0?'Cuentahabiente':'Inmueble',
            felHabilitado: this.$store.getters['auth/felHabilitado'],
			lkpMonedas: this.$store.state.auth.currentAppCtx.Monedas,
			ctahabientes: [],
			documentosConSaldoDlg: false,
			docSeleccionado: null,
			cargosPendientes: [],
            total_disponible: 0.0,
			totalADocumentoAPagar: 0.0,
			saldoTotal: 0.0,
            cargosAEmitir: null,
			expandedRows: [],
			loading: false,
			loadingCtaHabientes: false,
			filters: {},
			submitted: false
		}
	},
	mounted()
	{
		this.generar();
	},
	computed: {
		totalPagos() {
			let total = 0;
			for(let cargo of this.cargosPendientes) {
				total += cargo.pago;
			}

			return total;
		},	
		saldoDisponible()
		{
			return this.docSeleccionado.id_moneda + ' ' + this.formatCurrency(this.docSeleccionado.saldo);
		}
	},
	methods: {
		formatDocument(Docto)
		{
			return Docto.toString().padStart(6, '0');
		},		
		aplicar(mov){
			this.loading = true;
		
			this.totalADocumentoAPagar = 0.0;
			this.saldoTotal = 0.0;

			CxcService.getCuotasConSaldo(this.$store.state.auth.currentAppCtx, mov.id_cta_habiente_info, mov.id_moneda)	
			.then(
				data => {
					this.docSeleccionado = mov;		
					this.docSeleccionado.documento =  `${mov.serie}-${this.formatDocument(mov.no_docto)}`;
									
					let i;
					let totalDisponible = mov.saldo;

					for (i = 0; i < data.length; i++) {						
						this.saldoTotal += data[i].saldo;						

						if (totalDisponible > 0) {
							if(data[i].saldo > totalDisponible) {
								data[i].pago = totalDisponible;
							}else {
								data[i].pago = data[i].saldo;
							}
							totalDisponible = totalDisponible - data[i].pago;
						} else {
							data[i].pago = 0;
						}						
						this.totalADocumentoAPagar += data[i].pago;
					}	

					this.cargosPendientes = data;	
					this.loading = false;
					this.documentosConSaldoDlg = true;
				}
			).catch(
				data => {
					this.cargosPendientes = [];
					this.loading = false;
					this.$toast.add({severity:'error', summary: 'Error', detail: data, life: 3000})
				}
			);			
		},
		generar(){
			this.loadingCtaHabientes = true;
			this.total_disponible = 0.0;
			CxcService.getResumenSaldosPendienteAplicar(this.$store.state.auth.currentAppCtx, this.idMoneda).then(data => {					
				this.loadingCtaHabientes = false;				
				this.ctahabientes = data;	

				this.total_disponible = 0.0;	
				let i;
				for (i = 0; i < this.ctahabientes.length; i++) {
					this.total_disponible += this.ctahabientes[i].disponible;
				}							
			}).catch(err => {
				this.loadingCtaHabientes = false;
				this.ctahabientes = []
				this.$toast.add({severity:'error', summary: 'Error', detail: err, life: 3000})				
				}
			);	
		},
		aplicarDocumento() {			
			let abonos = this.cargosPendientes.filter(a => a.pago > 0.00);
			
			let mov = this.docSeleccionado;
			mov.abonos = [];
			let a;
			for(a in abonos)				
			{
				mov.abonos.push(
						{			
						id_movimiento_detalle: "",
						id_movimiento: "",
						id_cuota: abonos[a].id_cuota,
						anulado: "N",
						fecha_creacion: new Date(),
						monto_original: abonos[a].monto,
						aplicado: abonos[a].pago,
						saldo: abonos[a].monto - abonos[a].pago,
						monto: abonos[a].pago,
						mora: 0.0
						})	
			}	
			this.loading = true;
			CxcService.registrarAbono(this.$store.state.auth.currentAppCtx, mov).then(() =>
			{					
					this.docSeleccionado;
					mov = {};
					this.loading = false;	
					
					this.$toast.add({severity:'success', summary: 'Exito', detail: 'La acreditación ha sido aplicada con éxito!', life: 3000});
										
					this.cargosPendientes = [];												
					this.documentosConSaldoDlg = false;						
					this.generar();								
			}
			).catch(
				data => {
					this.loading = false;
					console.log("error: " + data);
					this.$toast.add({severity:'error', summary: 'Error', detail: data, life: 3000})
				}
			);				
		},
        claseResumen(data) {
            return [
                {
                    'vencido': data.id === "0",
                    'lowstock': data.quantity > 0 && data.quantity<10,
                    'instock': data.quantity > 10
                 }
            ];
        },	
        expandAll() {
            this.expandedRows = this.ResumenData.filter(p => p.id);
        },
        collapseAll() {
            this.expandedRows = null;
        },
		formatCurrency(value) {
			return parseFloat(value).toLocaleString('en-US', {minimumFractionDigits: 2});
		},	
		exportCSV() {
			this.$refs.dt.exportCSV();
		}
	}
}
</script>

<style scoped lang="scss">
.table-header {
	display: flex;
	justify-content: space-between;
}

.product-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .product-image {
	width: 150px;
	margin: 0 auto 2rem auto;
	display: block;
}

.confirmation-content {
	display: flex;
	align-items: center;
	justify-content: center;
}

.product-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-instock {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-outofstock {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-lowstock {
		background: #FEEDAF;
		color: #8A5340;
	}
}
/* Responsive */
.p-datatable-customers .p-datatable-tbody > tr > td .p-column-title {
	display: none;
}

@media screen and (max-width: 960px) {
	::v-deep(.p-datatable) {
		&.p-datatable-customers {
			.p-datatable-thead > tr > th,
			.p-datatable-tfoot > tr > td {
				display: none !important;
			}

			.p-datatable-tbody > tr {
				border-bottom: 1px solid var(--surface-d);

				> td {
					text-align: left;
					display: block;
					border: 0 none !important;
					width: 100% !important;
					float: left;
					clear: left;
					border: 0 none;

					&:last-child {
						text-align: center;
					}

					.p-column-title {
						padding: .4rem;
						min-width: 30%;
						display: inline-block;
						margin: -.4rem 1rem -.4rem -.4rem;
						font-weight: bold;
					}

					.p-progressbar {
						margin-top: .5rem;
						display: inline-block;
						width: 60%;
					}

					.p-rating {
						display: inline-block;
					}
				}
			}

			.p-datatable-tbody > tr.p-rowgroup-footer{
				display: flex;
			}
		}
	}
}

::v-deep(.p-toolbar) {
	flex-wrap: wrap;
	.p-button {
		margin-bottom: .25rem;
	}
}

::v-deep(.row-anulada) {
    background-color: rgba(0,0,0,.15) !important;
}

.vencido {
    font-weight: 700;
    color: red;
}

</style>    